// SCSS styles

// Variables
$lightGrey: #F1F3F5;
$midGrey: #E0E5E9;
$darkGrey: #959595;
$green: #7ED321;
$amber: #F5A623;
$red: #D0021B;
$hologicBlue: #292B63;
$hologicTurquoise: #317793;
$hologicPurple: #AF3DB2;

// Fonts
@font-face {
	font-family: 'ProximaNova-Light';
	src: url('./fonts/ProximaNova-Light.ttf');
}

@font-face {
	font-family: 'ProximaNova-Regular';
	src: url('./fonts/ProximaNova-Regular.ttf');
}

@font-face {
	font-family: 'ProximaNova-Bold';
	src: url('./fonts/ProximaNova-Bold.ttf');
}

@font-face {
	font-family: 'ProximaNova-Semibold';
	src: url('./fonts/ProximaNova-Semibold.ttf');
}

// Global styles
body {
	background-color: $lightGrey;
	font-family: 'ProximaNova-Regular';
	font-size: 1.25em;

	&.modal-open {
		overflow: hidden;
	}
}

.scroll-wrapper {
	background: 'red';
	-webkit-overflow-scrolling: touch;
    overflow-y: scroll;
}

.demo-iframe-holder {
	position: fixed; 
	right: 0; 
	bottom: 0; 
	left: 0;
	top: 0;
	-webkit-overflow-scrolling: touch;
	overflow-y: scroll;
  }
  
  .demo-iframe-holder iframe {
	height: 100%;
	width: 100%;
  }

h1 {
	font-family: 'ProximaNova-Semibold';
	font-size: 1.3em;
	font-weight: normal;
	margin-top: 0;
	// padding-left: 15px;
	// padding-right: 15px;
}

h2 {
	font-family: 'ProximaNova-Bold';
	font-weight: normal;
	font-size: 1.2em;
	margin-bottom: 15px !important; // Addition
}

h3 {
	//margin-top: 5px;
	font-family: 'ProximaNova-Bold';
	font-weight: normal;
	margin-bottom: 10px !important; // Addition
}

h4 {
	font-family: 'ProximaNova-Bold';
	font-weight: normal;
	color: $darkGrey;
	font-size: 0.6em;
	margin-bottom: 20px !important; // Addition
	// padding-bottom: 0;
	display: flex;
	margin-top: 5px;
}

h5 {
	font-family: 'ProximaNova-Bold';
	font-weight: normal;
	color: white;
	font-size: 1em;
	background-color: $hologicBlue;
	padding: 5px 15px;
	margin: 0 0 15px 0;
}

p {
	font-size: 0.9em;

	&.warning {
		background-color: rgba($red, 0.1);
		color: $red;
		padding: 10px;
		text-align: center;
	}

	&.less {
		margin: 0 0 5px 0;
	}

	&.white {
		color: white;
	}
}

.bold {
	font-family: 'ProximaNova-Bold';
}

.semiBold {
	font-family: 'ProximaNova-Semibold';
}

span {

	// font-size: 0.75em;
	&.gdpr {
		display: block;
		font-size: 0.65em;
		margin: 10px 0;
	}

	// Addition
	&.comment {
		font-size: 0.75em;
		display: inline-block;
		margin: 10px 0;
	}
}

select,
select:focus {
	-webkit-appearance: none;
	// height: 38px;
	text-align: center;
	color: black;
	font-size: 12px;
	padding: 11px 5px;
	padding-right: 30px;
	background-color: white;
	border: 1px solid $midGrey;
	width: 100%;
}

select[disabled] { color: rgba(black, 0.3); }

.select-wrapper {
	width: 100%;
	position: relative;
	display: inline-block;
	// &.required {
	// 	border: 1px solid $red;
	// }
}

.select-wrapper:after {
	content: '\0025BC';
	font-size: 12px;
	right: 14px;
	top: -3px;
	height: 26px;
	padding: 15px 0px 0px 8px;
	position: absolute;
	pointer-events: none;
}

a {
	&.self {
		background: $hologicBlue;
		color: white;
		font-size: 0.75em;
		padding: 8px;
		text-decoration: none;
	}
}

input::file-selector-button {
	background: $hologicTurquoise;
	border: 0;
	color: white;
	cursor: pointer;
	font-family: 'ProximaNova-Semibold';
	font-size: 11px;
	margin: 12px 8px 12px 0;
	max-height: 34px;
	outline: 0;
	padding: 10px;
	text-transform: uppercase;
}

button,.button {
//button, input::file-selector-button {
	//background: white;
	background: $hologicTurquoise;
	border: 0;
	color: white;
	cursor: pointer;
	font-family: 'ProximaNova-Semibold';
	font-size: 14px;
	margin-right: 10px;
	margin-bottom: 18px; // Addition
	// max-height: 34px;
	outline: 0;
	padding: 10px;
	text-transform: uppercase;

	// width: 46%; // Addition
	// &.home, &.fixed {
	// 	padding: px 0;
	// 	// width: 100px;
	// }
	&.half {
		width: 48%;
		margin-right: 0;
	}

	&.hundred {
		width: 100%;
		margin-right: 0;
	}

	&.participating {
		// width: 90px;
	}

	&.darken {
		background-color: rgba($hologicTurquoise, 1.25);
	}

	&:disabled {
		background: rgba($hologicTurquoise, 0.75);
	}

	&.emailError {
		background: none;
		color: $red;

		&:hover {
			background: none !important;
			color: $red !important;
		}
	}

	&.modalHeader {
		text-align: right;
		margin-bottom: 0 !important;
		width: 100%;
	}

	&.tableButton {
		background-color: $hologicBlue;
		border-radius: 20px;
		padding: 2px;

		img {
			max-width: 10px;
			height: auto;
		}

		&.tableButtonHighlight {
			background-color: #317793;

			&:hover {
				background-color: #317793;
			}
		}
	}

	&.secondary {
		background: white;
		width: auto;
		border: 1px solid $hologicBlue;
		// border-radius: 20px;
		color: $hologicBlue;
		font-size: 0.65em;
		padding: 8px;
	}

	&.edit {
		//justify-self: end;
		// background: rgba($hologicTurquoise,0.3);
		// color: $hologicTurquoise;
	}

	&.green {
		background-color: $green;
		color: white;
	}

	&.red {
		background-color: $red;
		color: white;
	}

	&.amber {
		background-color: $amber;
		color: white;
	}

	&:hover:not(:disabled) {
		cursor: pointer;
		background: $hologicBlue;
		color: white;
	}

	form & {
		margin: 12px 8px 0 0;
	}

	.fileInput & {
		margin-top: 0;
	}

	&.new {
		&.group {
			// margin-right: 15px;
		}

		&.event {
			margin-right: 0;
			// margin-right: 30px;
		}

		&.header {
			align-self: center;
			// margin-right: 45px;
		}
	}

	&.showHide {
		background: none;
		display: flex;
		color: black;
		font-size: 1em;
		font-family: 'ProximaNova-Regular';
		height: 32px;
		margin-bottom: 20px;
		line-height: 32px;
		padding: 0;
		justify-content: space-between;
		text-transform: none;
		width: 100%;

		div {

			//flex: 1;
			&.one {
				text-align: left;

				h2 {
					margin: 0 !important;
					padding: 0;
				}
			}

			&.two {
				background: white;
				border-radius: 20px;
				font-size: 12px;
				width: 50px;
				height: 25px;
				margin-top: 5px;
				text-align: center;

				hr {
					color: $hologicBlue;
				}

				div {
					color: $hologicBlue;
					margin-top: -3px;
				}
			}
		}

		&:hover {
			background: none;
			color: black;
		}
	}
}

section {
	//border-bottom: 1px solid $midGrey;
	// margin: 25px 0 25px 0 !important;
	margin: 10px 0 10px 0 !important; // Addition
	padding: 10px 0;

	&.event {
		// background: rgba(white, 1);
		// border: 1px solid $midGrey;
		// box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.02);
		// padding: 15px;
		// padding: 15px 0;
	}

	.eventHeader {
		// background-color: rgba(white,0.5);
		// border: 1px solid $midGrey;
		// border-bottom: none;
		padding: 0px 0px 10px 0;
		// text-align: right;
	}

	span.selfRegEdit {
		font-size: 0.75em;
		&.link {
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.dot {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin-right: 5px;
	position: relative;
	top: 1px;
	&.red {
		background-color: $red;
	}
	&.green {
		background-color: $green;
	}
}

.errorShade {
	background-color: $lightGrey;
}

table {
	border: 1px solid $midGrey;
	font-size: 0.75em;
	line-height: 0.95em;
	width: 100%;

	tr {
		&:nth-child(odd) {
			background: rgba($hologicBlue, 0.1);
		}

		&:nth-child(even) {
			background: white;
		}

		&.activityGroup {
			background: rgba($hologicTurquoise, 0.25);

			td {
				padding-left: 15px;
			}
		}

		// &.emailError {
		// 	background: rgba($red,0.2);
		// }
		&.activity td {
			padding-left: 30px;
		}

		&.participating {
			background: rgba($green, 0.2);
		}

		&.padding td {
			padding: 10px 3px;
		}

		// &:not(:last-child) td {
		// 	border-bottom: 1px solid $lightGrey;
		// }
		// td {
		// 	// border-bottom: 1px solid $lightGrey;
		// }
		td {
			display: block;
			// padding: 3px;
			padding: 8px;

			&:first-child {
				margin-top: 15px;
			}

			// width: 20%; // Addition
			&.alignRight {
				text-align: right;
			}

			&.activityWide {
				display: block;
				width: 100%;
			}

			&.fifty {
				width: 50%;
			}

			&.sixty {
				width: 60%;
			}

			&.emailError {
				color: $red;
				a {
					color: $red !important;
				}
			}
		}
	}

	thead tr {
		background: rgba($hologicBlue, 1) !important;
		display: none; // Addition
		color: white;
		// font-size: 1.25em;
		// font-weight: bold;

		td {
			padding: 10px 3px;
		}
	}
}

img {
	&.eventLogo {
		border: 1px solid $midGrey;
		display: block;
		max-width: 100px;
		padding: 10px;
		height: auto;
		&.self {
			margin-bottom: 15px;
		}
	}
}

form {
	margin-bottom: 20px;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="date"],
input[type="time"],
input[type="number"] {
	// font-size: 0.75em;
	border: 1px solid $midGrey;
	display: block;
	font-family: 'ProximaNova-Regular';
	height: 38px;
	margin-bottom: 5px;
	// width: 300px !important;
	width: 100%; // Addition
	box-sizing: border-box;
	-webkit-appearance: none;

	&.mobileMargin {
		margin-bottom: 15px;
	}
}

input[type="file"] {
	display: block;
	// margin-bottom: 10px;
}

input[type="search"] {
	border: 1px solid $midGrey;
	display: block;
	height: 38px;
	-webkit-appearance: none;
	margin-bottom: 20px;
	width: 300px;
}

input[type=checkbox] {
	display: inline;
	border: 1px solid black;
	width: 22px;
	height: 22px;
}

textarea {
	border: 1px solid $midGrey;
	//font-size: 0.75em;
	font-family: 'ProximaNova-Regular';
	// width: 300px;
	box-sizing: border-box;
	width: 100%; // Addition
}

span.label {
	font-size: 0.85em;
	padding-left: 5px;
}

.checkboxGroup {
	display: flex;
	span {
		margin-left: 5px;
		padding: 5px 0;
		vertical-align: middle;
	}
}

.inputGroup {
	&.multi {
		display: flex;
	}

	span {
		font-size: 0.75em;
	}

	select {
		margin-right: 10px;
		// width: 306px;
	}

	select,
	textArea {
		display: block;
	}
}

.duration {
	min-width: 60px;
}

.required {
	// background-color: rgba($red, 0.08);
	border: 1px solid $red !important;
}

.requiredText {
	color: $red;
	// font-weight: bold;
}

.no-suggestions {
	color: #999;
	padding: 0.5rem;
}

.suggestions {
	//border: 1px solid #999;
	border-top-width: 0;
	list-style: none;
	margin-top: 0;
	max-height: 143px;
	overflow-y: auto;
	padding-left: 0;
	width: calc(300px);
}

.suggestions li {
	padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
	background-color: $hologicBlue;
	color: #fff;
	cursor: pointer;
	font-weight: 700;
}

.suggestions li:not(:last-of-type) {
	border-bottom: 1px solid #999;
}

// Header
header {
	box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.02);
	font-family: 'ProximaNova-Semibold';
	margin-bottom: 20px;

	.preHeader {
		align-items: center;
		background-color: $lightGrey;
		display: flex;
		height: 30px;
		justify-content: space-between;
		padding: 0 5%;
		font-size: 0.55em;

		span {
			cursor: pointer;
			// font-size: 0.85em;
			margin-left: 5px;
			&:hover {
				text-decoration: underline;
			}
			&.headerRole {
				background-color: $darkGrey;
				border-radius: 3px;
				color: white;
				cursor: default;
				font-size: 0.95em;
				padding: 2px 4px;
				// text-transform: capitalize;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.mainHeader {
		align-items: center;
		background-color: white;
		// display: flex;
		font-family: 'ProximaNova-Semibold';
		font-size: 1.3em;
		font-weight: normal;
		justify-content: space-between;
		padding: 20px 5%;

		div {
			// width: 30%;
			text-align: center; // Addition

			&.alignRight {
				// text-align: right;
			}

			// Addition
			button {
				margin-top: 20px;
			}
		}
	}

	img {
		cursor: pointer;
		height: auto;
		width: 180px;
	}
}

// QR video
.qrHolder {
	padding: 0;
	margin: 0;
	position: relative;
}

.qrHeader {
	background-color: $hologicBlue;
	height: 38px;
	padding-left: 6px;
	padding-right: 6px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	h3 {
		color: white;
		padding: 0 !important;
		margin: 0 !important;
	}

	button {
		margin: 0;
		max-width: 60px;
	}
}

p.qrStatus {
	background-color: $green;
	color: white;
	margin: 0;
	padding: 8px 0;
	position: absolute;
	top: 81px;
	text-align: center;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 1000;

	&.red {
		background-color: $red;
	}

	&.amber {
		background-color: $amber;
	}
}

img.qrTick {
	width: 70px;
	height: 70px;
	position: absolute;
	top: 250px;
	right: 0;
	left: 0;
	margin: 0 auto;
	z-index: 2000;
}

img.qrImage {
	width: 200px;
}

#videoId {
	height: 400px !important;
	top: 81px !important;
}

.videoContainer {
	background-color: white;
	margin: 0 !important;
	padding: 0 !important;
	height: 400px !important;

	div {
		padding-top: 0 !important;
		position: static !important;
	}
}

// Page
.page {
	//padding: 0 0 30px 0;
	padding: 0 5% 30px 5%;
}

// Modal
.modal {
	background: rgba($lightGrey, 0.9);
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;

	div {
		background: white;
		position: fixed;
		top: 50%;
		left: 50%;
		min-width: 300px;
		transform: translate(-50%, -50%);
		box-sizing: border-box;
		padding: 10px 20px 20px 20px;

		p {
			white-space: pre-line;
		}
	}
}

.loading {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

// Group / activity layout
.activityGroup {
	background-color: $lightGrey;
	margin-bottom: 20px;
	// padding: 10px 15px;
	padding: 10px 0;
}

.card {
	background-color: white;
	border-bottom: none !important;
	//cursor: pointer;
	margin: 15px 0 15px 0;
	padding: 15px 15px 20px 15px;
	position: relative;

	&.settings {
		margin-top: 30px;
		padding: 0;

		.inner {
			padding: 0 15px 10px 15px;
		}
	}

	&.green {
		border-top: 6px solid $green;
	}

	&.amber {
		border-top: 6px solid $amber;
	}

	&.red {
		border-top: 6px solid $red;
	}

	&.overview {
		margin-bottom: 0;
		margin-top: 5px;
	}

	.alignRight {
		border: 1px solid grey;
		position: absolute;
		right: 15px;
		bottom: 20px;
	}

	// &:hover {
	// 	button {
	// 		background: $hologicBlue;
	//     color: white;
	// 	}
	// }
	// &.home {
	// 	cursor: pointer;
	// }
	h2,
	h3 {
		margin-bottom: 0;
		padding-bottom: 0;
		margin-top: 0;
		padding-top: 0;
	}

	h4 {
		margin-bottom: 0;
	}
	button {
		margin-bottom: 10px !important;
	}
}

// Page section
.box {
	background-color: rgba($hologicBlue, 0.1);
}

.stats {

	//display: flex;
	// margin-left: 20px; // Addition
	span {
		background: $lightGrey;
		border-radius: 20px;
		color: black;
		display: inline-block !important;
		font-size: 0.35em;
		// font-weight: bold;
		margin-bottom: 5px;
		margin-right: 5px;
		padding: 6px 8px;
		text-align: center;
		width: 46% !important; // Addition
		box-sizing: border-box; // Addition
	}

	&.home {
		margin: 10px auto;
		text-align: center; // Addition
	}
}

.justify {
	.limit {
		margin-top: 0;
	}

	p.limit {
		margin-top: 5px;
	}
}

.justifyMobile {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	.edit {
		// width: 100px;
	}
}

.noMargin {
	margin: 0;
}

.firstButtons {
	margin-bottom: 0px;
}

.error {
	color: $red;
	font-size: 0.8em;
}

.qrGroup {
	background: white;
	// padding: 20px;
	// display: flex;
}

.qrSpacer {
	width: 20px
}

.qrLink {
	display: none;
}

.qrH3 {
	padding-top: 0;
	margin-top: 0;
	// margin-bottom: 5px !important;
}

// @media screen and (-webkit-min-device-pixel-ratio:0) { 
// 	select:focus,
// 	textarea:focus,
// 	input:focus {
// 	  font-size: 16px;
// 	  background: #eee;
// 	}
//   }

@media only screen and (max-width: 768px) {

	input[type="color"],
	input[type="date"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="email"],
	input[type="month"],
	input[type="number"],
	input[type="password"],
	input[type="search"],
	input[type="tel"],
	input[type="text"],
	input[type="time"],
	input[type="url"],
	input[type="week"],
	// select:focus,
	textarea {
		font-size: 16px;
	}

	.showMobile {
		display: 'block';
		margin-left: 0px;
	}

	.showDesktop {
		display: none;
		margin-left: 0px;
	}
}

@media only screen and (min-width: 768px) {
	body {
		font-size: 1em;
	}

	// h1 {
	// 	padding-left: 0;
	// 	padding-right: 0;
	// }

	h2,
	h3,
	h4 {
		margin-bottom: 0;
	}

	h2 {
		font-size: 1.4em;
	}

	h4 {
		font-size: 0.9em;
	}

	.page {
		padding: 0 5% 30px 5%;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="date"],
	input[type="time"],
	input[type="number"],
	.duration {
		height: 25px;

		&.mobileMargin {
			margin-bottom: 15px;
		}
	}

	input[type=checkbox] {
		width: 12px;
		height: 12px;
	}

	input::file-selector-button {
		margin: 0px 8px 0px 0;
		width: auto;
		padding: 8px 6px;
		max-height: 30px;
	}
	button,.button {
		margin-bottom: 0px; // Addition
		font-size: 11px;
		width: auto;
		padding: 8px 6px;
		max-height: 30px;

		&.hundred,
		&.half:not(.new) {
			width: auto;
			margin-right: 10px;
		}

		&.home {
			width: 115px;
		}

		&.fixed {
			width: 88px;
		}

		&.secondary {
			font-size: 0.85em;
			padding: 5px;
		}
	}

	table {
		font-size: 0.85em;

		tr {
			&:not(:last-child) td {
				border-bottom: 1px solid $lightGrey;
			}

			td {
				display: table-cell;
				padding: 3px;
				width: 17%;

				&:nth-child(5) {
					width: 24%;
				}

				// &:nth-child(6) {
				// 	width: 5% !important;
				// }
			}
		}

		thead tr {
			display: table-row;
		}

		&.userSearch {
			td {
				width: 16.5%
			}
		}
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="date"],
	input[type="time"],
	input[type="number"],
	.duration,
	.select-wrapper {
		width: 300px;
	}

	select.auto,
	.select-wrapper.auto {
		width: auto;
	}

	select,
	.select-wrapper {
		height: 25px;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.select-wrapper:after {
		top: -9px;
		height: 26px;
		padding: 15px 0px 0px 8px;
	}

	textarea {
		width: 300px;
	}

	section {
		margin: 25px 0 25px 0 !important;
		div.event {
			background: rgba(white, 1);
			border: 1px solid $midGrey;
			box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.02);
			padding: 15px;
		}
		.eventHeader {
			background-color: rgba(white,0.5);
			border: 1px solid $midGrey;
			border-bottom: none;
			padding: 8px 15px;
			text-align: right;
			span.selfRegEdit {
				font-size: 0.75em;
			}
		}
	}

	// Group / activity layout
	.activityGroup {
		padding: 10px 15px;
		&.reception {
			padding: 10px 0; 
		}
	}

	.justifyMobile {
		display: inline-block;
	}

	.fileInput {
		display: flex;
		margin-bottom: 20px;
	}

	.marginTop {
		margin-top: 20px;
	}

	.showMobile {
		display: none;
		margin-left: 0px !important;
	}

	.showDesktop {
		margin-left: 0px !important;
	}

	.stats {
		display: flex;
		// font-size: 0.85em;
		margin-left: 20px;

		span {
			font-size: 0.5em;
			width: auto !important;
			margin-bottom: 0;
		}

		&.home {
			margin-top: 2px;
			//text-align: center; // Addition
		}
	}

	.modal {
		div {
			// height: 170px;
			width: 350px;
		}
	}

	.firstButtons {
		margin-bottom: 0;
	}

	.justify {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.limit {
			width: 65%;
		}

		&.bottom {
			align-items: center;
		}
	}

	header {
		.mainHeader {
			display: flex;

			div {
				text-align: left;
				width: 30%;

				// Addition
				button {
					margin-top: 0px;
				}

				&.alignRight {
					text-align: right;
				}
			}
		}
	}

	.qrLink {
		display: block;
		font-size: 0.8em;
		margin-bottom: 10px;
	}

	.qrH3 {
		margin-bottom: 5px !important;
	}

	header {
		.preHeader {
			font-size: 0.75em;
		}
	}

	.card {
		h1 {
			// max-width: 65%;
			// padding-right: 10px;
		}
	}
	.qrGroup {
		display: flex;
	}
}

@media only screen and (min-width: 1024px) {
	.justify {
		.limit {
			width: 70%;
		}
	}

	.showDesktop {
		display: 'block';
	}

	table {
		tr {
			td {
				width: 20%;
			}
		}
	}

	.stats {
		span {
			font-size: 0.7em;
		}
	}
}

@media only screen and (min-width: 1200px) {
	// .justify {
	// 	* {
	// 		border: 1px solid green;
	// 	}
	// 	.limit {
	// 		width: 80%;
	// 	}
	// }
}

.selfSwitch {
	p {
		display: inline-block;
		font-size: 0.8em;
		margin-right: 10px;
	}
}

.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 30px;
	margin-right: 10px;
	top: -4px;
  }
  
  .switch input { 
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  .slider:before {
	position: absolute;
	content: "";
	height: 24px;
	width: 24px;
	left: 3px;
	bottom: 3px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  input:checked + .slider {
	background-color: $hologicTurquoise;
  }
  
  input:focus + .slider {
	box-shadow: 0 0 1px $hologicTurquoise;
  }
  
  input:checked + .slider:before {
	-webkit-transform: translateX(20px);
	-ms-transform: translateX(20px);
	transform: translateX(20px);
  }
  
  /* Rounded sliders */
  .slider.round {
	border-radius: 30px;
  }
  
  .slider.round:before {
	border-radius: 50%;
  }