@charset "UTF-8";
@font-face {
  font-family: "ProximaNova-Light";
  src: url("./fonts/ProximaNova-Light.ttf");
}
@font-face {
  font-family: "ProximaNova-Regular";
  src: url("./fonts/ProximaNova-Regular.ttf");
}
@font-face {
  font-family: "ProximaNova-Bold";
  src: url("./fonts/ProximaNova-Bold.ttf");
}
@font-face {
  font-family: "ProximaNova-Semibold";
  src: url("./fonts/ProximaNova-Semibold.ttf");
}
body {
  background-color: #F1F3F5;
  font-family: "ProximaNova-Regular";
  font-size: 1.25em;
}
body.modal-open {
  overflow: hidden;
}

.scroll-wrapper {
  background: "red";
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.demo-iframe-holder {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.demo-iframe-holder iframe {
  height: 100%;
  width: 100%;
}

h1 {
  font-family: "ProximaNova-Semibold";
  font-size: 1.3em;
  font-weight: normal;
  margin-top: 0;
}

h2 {
  font-family: "ProximaNova-Bold";
  font-weight: normal;
  font-size: 1.2em;
  margin-bottom: 15px !important;
}

h3 {
  font-family: "ProximaNova-Bold";
  font-weight: normal;
  margin-bottom: 10px !important;
}

h4 {
  font-family: "ProximaNova-Bold";
  font-weight: normal;
  color: #959595;
  font-size: 0.6em;
  margin-bottom: 20px !important;
  display: flex;
  margin-top: 5px;
}

h5 {
  font-family: "ProximaNova-Bold";
  font-weight: normal;
  color: white;
  font-size: 1em;
  background-color: #292B63;
  padding: 5px 15px;
  margin: 0 0 15px 0;
}

p {
  font-size: 0.9em;
}
p.warning {
  background-color: rgba(208, 2, 27, 0.1);
  color: #D0021B;
  padding: 10px;
  text-align: center;
}
p.less {
  margin: 0 0 5px 0;
}
p.white {
  color: white;
}

.bold {
  font-family: "ProximaNova-Bold";
}

.semiBold {
  font-family: "ProximaNova-Semibold";
}

span.gdpr {
  display: block;
  font-size: 0.65em;
  margin: 10px 0;
}
span.comment {
  font-size: 0.75em;
  display: inline-block;
  margin: 10px 0;
}

select,
select:focus {
  -webkit-appearance: none;
  text-align: center;
  color: black;
  font-size: 12px;
  padding: 11px 5px;
  padding-right: 30px;
  background-color: white;
  border: 1px solid #E0E5E9;
  width: 100%;
}

select[disabled] {
  color: rgba(0, 0, 0, 0.3);
}

.select-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
}

.select-wrapper:after {
  content: "▼";
  font-size: 12px;
  right: 14px;
  top: -3px;
  height: 26px;
  padding: 15px 0px 0px 8px;
  position: absolute;
  pointer-events: none;
}

a.self {
  background: #292B63;
  color: white;
  font-size: 0.75em;
  padding: 8px;
  text-decoration: none;
}

input::file-selector-button {
  background: #317793;
  border: 0;
  color: white;
  cursor: pointer;
  font-family: "ProximaNova-Semibold";
  font-size: 11px;
  margin: 12px 8px 12px 0;
  max-height: 34px;
  outline: 0;
  padding: 10px;
  text-transform: uppercase;
}

button, .button {
  background: #317793;
  border: 0;
  color: white;
  cursor: pointer;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 18px;
  outline: 0;
  padding: 10px;
  text-transform: uppercase;
}
button.half, .button.half {
  width: 48%;
  margin-right: 0;
}
button.hundred, .button.hundred {
  width: 100%;
  margin-right: 0;
}
button.darken, .button.darken {
  background-color: #317793;
}
button:disabled, .button:disabled {
  background: rgba(49, 119, 147, 0.75);
}
button.emailError, .button.emailError {
  background: none;
  color: #D0021B;
}
button.emailError:hover, .button.emailError:hover {
  background: none !important;
  color: #D0021B !important;
}
button.modalHeader, .button.modalHeader {
  text-align: right;
  margin-bottom: 0 !important;
  width: 100%;
}
button.tableButton, .button.tableButton {
  background-color: #292B63;
  border-radius: 20px;
  padding: 2px;
}
button.tableButton img, .button.tableButton img {
  max-width: 10px;
  height: auto;
}
button.tableButton.tableButtonHighlight, .button.tableButton.tableButtonHighlight {
  background-color: #317793;
}
button.tableButton.tableButtonHighlight:hover, .button.tableButton.tableButtonHighlight:hover {
  background-color: #317793;
}
button.secondary, .button.secondary {
  background: white;
  width: auto;
  border: 1px solid #292B63;
  color: #292B63;
  font-size: 0.65em;
  padding: 8px;
}
button.green, .button.green {
  background-color: #7ED321;
  color: white;
}
button.red, .button.red {
  background-color: #D0021B;
  color: white;
}
button.amber, .button.amber {
  background-color: #F5A623;
  color: white;
}
button:hover:not(:disabled), .button:hover:not(:disabled) {
  cursor: pointer;
  background: #292B63;
  color: white;
}
form button, form .button {
  margin: 12px 8px 0 0;
}
.fileInput button, .fileInput .button {
  margin-top: 0;
}
button.new.event, .button.new.event {
  margin-right: 0;
}
button.new.header, .button.new.header {
  align-self: center;
}
button.showHide, .button.showHide {
  background: none;
  display: flex;
  color: black;
  font-size: 1em;
  font-family: "ProximaNova-Regular";
  height: 32px;
  margin-bottom: 20px;
  line-height: 32px;
  padding: 0;
  justify-content: space-between;
  text-transform: none;
  width: 100%;
}
button.showHide div.one, .button.showHide div.one {
  text-align: left;
}
button.showHide div.one h2, .button.showHide div.one h2 {
  margin: 0 !important;
  padding: 0;
}
button.showHide div.two, .button.showHide div.two {
  background: white;
  border-radius: 20px;
  font-size: 12px;
  width: 50px;
  height: 25px;
  margin-top: 5px;
  text-align: center;
}
button.showHide div.two hr, .button.showHide div.two hr {
  color: #292B63;
}
button.showHide div.two div, .button.showHide div.two div {
  color: #292B63;
  margin-top: -3px;
}
button.showHide:hover, .button.showHide:hover {
  background: none;
  color: black;
}

section {
  margin: 10px 0 10px 0 !important;
  padding: 10px 0;
}
section .eventHeader {
  padding: 0px 0px 10px 0;
}
section span.selfRegEdit {
  font-size: 0.75em;
}
section span.selfRegEdit.link {
  cursor: pointer;
}
section span.selfRegEdit.link:hover {
  text-decoration: underline;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 5px;
  position: relative;
  top: 1px;
}
.dot.red {
  background-color: #D0021B;
}
.dot.green {
  background-color: #7ED321;
}

.errorShade {
  background-color: #F1F3F5;
}

table {
  border: 1px solid #E0E5E9;
  font-size: 0.75em;
  line-height: 0.95em;
  width: 100%;
}
table tr:nth-child(odd) {
  background: rgba(41, 43, 99, 0.1);
}
table tr:nth-child(even) {
  background: white;
}
table tr.activityGroup {
  background: rgba(49, 119, 147, 0.25);
}
table tr.activityGroup td {
  padding-left: 15px;
}
table tr.activity td {
  padding-left: 30px;
}
table tr.participating {
  background: rgba(126, 211, 33, 0.2);
}
table tr.padding td {
  padding: 10px 3px;
}
table tr td {
  display: block;
  padding: 8px;
}
table tr td:first-child {
  margin-top: 15px;
}
table tr td.alignRight {
  text-align: right;
}
table tr td.activityWide {
  display: block;
  width: 100%;
}
table tr td.fifty {
  width: 50%;
}
table tr td.sixty {
  width: 60%;
}
table tr td.emailError {
  color: #D0021B;
}
table tr td.emailError a {
  color: #D0021B !important;
}
table thead tr {
  background: #292b63 !important;
  display: none;
  color: white;
}
table thead tr td {
  padding: 10px 3px;
}

img.eventLogo {
  border: 1px solid #E0E5E9;
  display: block;
  max-width: 100px;
  padding: 10px;
  height: auto;
}
img.eventLogo.self {
  margin-bottom: 15px;
}

form {
  margin-bottom: 20px;
}

input[type=text],
input[type=password],
input[type=email],
input[type=date],
input[type=time],
input[type=number] {
  border: 1px solid #E0E5E9;
  display: block;
  font-family: "ProximaNova-Regular";
  height: 38px;
  margin-bottom: 5px;
  width: 100%;
  box-sizing: border-box;
  -webkit-appearance: none;
}
input[type=text].mobileMargin,
input[type=password].mobileMargin,
input[type=email].mobileMargin,
input[type=date].mobileMargin,
input[type=time].mobileMargin,
input[type=number].mobileMargin {
  margin-bottom: 15px;
}

input[type=file] {
  display: block;
}

input[type=search] {
  border: 1px solid #E0E5E9;
  display: block;
  height: 38px;
  -webkit-appearance: none;
  margin-bottom: 20px;
  width: 300px;
}

input[type=checkbox] {
  display: inline;
  border: 1px solid black;
  width: 22px;
  height: 22px;
}

textarea {
  border: 1px solid #E0E5E9;
  font-family: "ProximaNova-Regular";
  box-sizing: border-box;
  width: 100%;
}

span.label {
  font-size: 0.85em;
  padding-left: 5px;
}

.checkboxGroup {
  display: flex;
}
.checkboxGroup span {
  margin-left: 5px;
  padding: 5px 0;
  vertical-align: middle;
}

.inputGroup.multi {
  display: flex;
}
.inputGroup span {
  font-size: 0.75em;
}
.inputGroup select {
  margin-right: 10px;
}
.inputGroup select,
.inputGroup textArea {
  display: block;
}

.duration {
  min-width: 60px;
}

.required {
  border: 1px solid #D0021B !important;
}

.requiredText {
  color: #D0021B;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 300px;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #292B63;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

header {
  box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.02);
  font-family: "ProximaNova-Semibold";
  margin-bottom: 20px;
}
header .preHeader {
  align-items: center;
  background-color: #F1F3F5;
  display: flex;
  height: 30px;
  justify-content: space-between;
  padding: 0 5%;
  font-size: 0.55em;
}
header .preHeader span {
  cursor: pointer;
  margin-left: 5px;
}
header .preHeader span:hover {
  text-decoration: underline;
}
header .preHeader span.headerRole {
  background-color: #959595;
  border-radius: 3px;
  color: white;
  cursor: default;
  font-size: 0.95em;
  padding: 2px 4px;
}
header .preHeader span.headerRole:hover {
  text-decoration: none;
}
header .mainHeader {
  align-items: center;
  background-color: white;
  font-family: "ProximaNova-Semibold";
  font-size: 1.3em;
  font-weight: normal;
  justify-content: space-between;
  padding: 20px 5%;
}
header .mainHeader div {
  text-align: center;
}
header .mainHeader div button {
  margin-top: 20px;
}
header img {
  cursor: pointer;
  height: auto;
  width: 180px;
}

.qrHolder {
  padding: 0;
  margin: 0;
  position: relative;
}

.qrHeader {
  background-color: #292B63;
  height: 38px;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.qrHeader h3 {
  color: white;
  padding: 0 !important;
  margin: 0 !important;
}
.qrHeader button {
  margin: 0;
  max-width: 60px;
}

p.qrStatus {
  background-color: #7ED321;
  color: white;
  margin: 0;
  padding: 8px 0;
  position: absolute;
  top: 81px;
  text-align: center;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
}
p.qrStatus.red {
  background-color: #D0021B;
}
p.qrStatus.amber {
  background-color: #F5A623;
}

img.qrTick {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 250px;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 2000;
}

img.qrImage {
  width: 200px;
}

#videoId {
  height: 400px !important;
  top: 81px !important;
}

.videoContainer {
  background-color: white;
  margin: 0 !important;
  padding: 0 !important;
  height: 400px !important;
}
.videoContainer div {
  padding-top: 0 !important;
  position: static !important;
}

.page {
  padding: 0 5% 30px 5%;
}

.modal {
  background: rgba(241, 243, 245, 0.9);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.modal div {
  background: white;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 300px;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 10px 20px 20px 20px;
}
.modal div p {
  white-space: pre-line;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.activityGroup {
  background-color: #F1F3F5;
  margin-bottom: 20px;
  padding: 10px 0;
}

.card {
  background-color: white;
  border-bottom: none !important;
  margin: 15px 0 15px 0;
  padding: 15px 15px 20px 15px;
  position: relative;
}
.card.settings {
  margin-top: 30px;
  padding: 0;
}
.card.settings .inner {
  padding: 0 15px 10px 15px;
}
.card.green {
  border-top: 6px solid #7ED321;
}
.card.amber {
  border-top: 6px solid #F5A623;
}
.card.red {
  border-top: 6px solid #D0021B;
}
.card.overview {
  margin-bottom: 0;
  margin-top: 5px;
}
.card .alignRight {
  border: 1px solid grey;
  position: absolute;
  right: 15px;
  bottom: 20px;
}
.card h2,
.card h3 {
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 0;
  padding-top: 0;
}
.card h4 {
  margin-bottom: 0;
}
.card button {
  margin-bottom: 10px !important;
}

.box {
  background-color: rgba(41, 43, 99, 0.1);
}

.stats span {
  background: #F1F3F5;
  border-radius: 20px;
  color: black;
  display: inline-block !important;
  font-size: 0.35em;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 6px 8px;
  text-align: center;
  width: 46% !important;
  box-sizing: border-box;
}
.stats.home {
  margin: 10px auto;
  text-align: center;
}

.justify .limit {
  margin-top: 0;
}
.justify p.limit {
  margin-top: 5px;
}

.justifyMobile {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.noMargin {
  margin: 0;
}

.firstButtons {
  margin-bottom: 0px;
}

.error {
  color: #D0021B;
  font-size: 0.8em;
}

.qrGroup {
  background: white;
}

.qrSpacer {
  width: 20px;
}

.qrLink {
  display: none;
}

.qrH3 {
  padding-top: 0;
  margin-top: 0;
}

@media only screen and (max-width: 768px) {
  input[type=color],
  input[type=date],
  input[type=datetime],
  input[type=datetime-local],
  input[type=email],
  input[type=month],
  input[type=number],
  input[type=password],
  input[type=search],
  input[type=tel],
  input[type=text],
  input[type=time],
  input[type=url],
  input[type=week],
  textarea {
    font-size: 16px;
  }
  .showMobile {
    display: "block";
    margin-left: 0px;
  }
  .showDesktop {
    display: none;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 768px) {
  body {
    font-size: 1em;
  }
  h2,
  h3,
  h4 {
    margin-bottom: 0;
  }
  h2 {
    font-size: 1.4em;
  }
  h4 {
    font-size: 0.9em;
  }
  .page {
    padding: 0 5% 30px 5%;
  }
  input[type=text],
  input[type=password],
  input[type=email],
  input[type=date],
  input[type=time],
  input[type=number],
  .duration {
    height: 25px;
  }
  input[type=text].mobileMargin,
  input[type=password].mobileMargin,
  input[type=email].mobileMargin,
  input[type=date].mobileMargin,
  input[type=time].mobileMargin,
  input[type=number].mobileMargin,
  .duration.mobileMargin {
    margin-bottom: 15px;
  }
  input[type=checkbox] {
    width: 12px;
    height: 12px;
  }
  input::file-selector-button {
    margin: 0px 8px 0px 0;
    width: auto;
    padding: 8px 6px;
    max-height: 30px;
  }
  button, .button {
    margin-bottom: 0px;
    font-size: 11px;
    width: auto;
    padding: 8px 6px;
    max-height: 30px;
  }
  button.hundred, button.half:not(.new), .button.hundred, .button.half:not(.new) {
    width: auto;
    margin-right: 10px;
  }
  button.home, .button.home {
    width: 115px;
  }
  button.fixed, .button.fixed {
    width: 88px;
  }
  button.secondary, .button.secondary {
    font-size: 0.85em;
    padding: 5px;
  }
  table {
    font-size: 0.85em;
  }
  table tr:not(:last-child) td {
    border-bottom: 1px solid #F1F3F5;
  }
  table tr td {
    display: table-cell;
    padding: 3px;
    width: 17%;
  }
  table tr td:nth-child(5) {
    width: 24%;
  }
  table thead tr {
    display: table-row;
  }
  table.userSearch td {
    width: 16.5%;
  }
  input[type=text],
  input[type=password],
  input[type=email],
  input[type=date],
  input[type=time],
  input[type=number],
  .duration,
  .select-wrapper {
    width: 300px;
  }
  select.auto,
  .select-wrapper.auto {
    width: auto;
  }
  select,
  .select-wrapper {
    height: 25px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .select-wrapper:after {
    top: -9px;
    height: 26px;
    padding: 15px 0px 0px 8px;
  }
  textarea {
    width: 300px;
  }
  section {
    margin: 25px 0 25px 0 !important;
  }
  section div.event {
    background: white;
    border: 1px solid #E0E5E9;
    box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.02);
    padding: 15px;
  }
  section .eventHeader {
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid #E0E5E9;
    border-bottom: none;
    padding: 8px 15px;
    text-align: right;
  }
  section .eventHeader span.selfRegEdit {
    font-size: 0.75em;
  }
  .activityGroup {
    padding: 10px 15px;
  }
  .activityGroup.reception {
    padding: 10px 0;
  }
  .justifyMobile {
    display: inline-block;
  }
  .fileInput {
    display: flex;
    margin-bottom: 20px;
  }
  .marginTop {
    margin-top: 20px;
  }
  .showMobile {
    display: none;
    margin-left: 0px !important;
  }
  .showDesktop {
    margin-left: 0px !important;
  }
  .stats {
    display: flex;
    margin-left: 20px;
  }
  .stats span {
    font-size: 0.5em;
    width: auto !important;
    margin-bottom: 0;
  }
  .stats.home {
    margin-top: 2px;
  }
  .modal div {
    width: 350px;
  }
  .firstButtons {
    margin-bottom: 0;
  }
  .justify {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .justify .limit {
    width: 65%;
  }
  .justify.bottom {
    align-items: center;
  }
  header .mainHeader {
    display: flex;
  }
  header .mainHeader div {
    text-align: left;
    width: 30%;
  }
  header .mainHeader div button {
    margin-top: 0px;
  }
  header .mainHeader div.alignRight {
    text-align: right;
  }
  .qrLink {
    display: block;
    font-size: 0.8em;
    margin-bottom: 10px;
  }
  .qrH3 {
    margin-bottom: 5px !important;
  }
  header .preHeader {
    font-size: 0.75em;
  }
  .qrGroup {
    display: flex;
  }
}
@media only screen and (min-width: 1024px) {
  .justify .limit {
    width: 70%;
  }
  .showDesktop {
    display: "block";
  }
  table tr td {
    width: 20%;
  }
  .stats span {
    font-size: 0.7em;
  }
}
.selfSwitch p {
  display: inline-block;
  font-size: 0.8em;
  margin-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
  margin-right: 10px;
  top: -4px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #317793;
}

input:focus + .slider {
  box-shadow: 0 0 1px #317793;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}/*# sourceMappingURL=App.css.map */